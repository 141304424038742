<template>
  <div class="household-energy-storage">
    <div class="wrap-content">
      <!-- 顶部盒子 -->
      <div class="top_box">
        <div class="top-title">
          <div class="left">
            <img :src="navImg" alt="" />
          </div>
          <div class="theme-title">
            <span>HUAWEI-6G</span>
            <img :src="iconArrow" alt="" />
          </div>
          <div class="right">
            <img :src="navImg2" alt="" />
            <img :src="navImg3" alt="" />
          </div>
        </div>
        <div class="status_box">
          <img :src="statusImg" alt="" />
          <span>72%</span>
          <div class="line"></div>
          <span style="color: #61e79e">充电</span>
        </div>
      </div>
      <!-- 内容 -->
      <div class="content-box">
        <div class="images-box">
          <div class="containe">
            <img :src="contentImg" alt="" />
            <span>网关</span>
            <div class="mask"></div>
          </div>

          <!-- 左分支盒子 -->
          <div class="left_line_box">
            <div
              class="line_item"
              v-for="(item, index) in 4"
              :key="index"
            ></div>
          </div>

          <!-- 右分支盒子 -->
          <div class="right_line_box">
            <div class="out_line">
              <div class="in_line"></div>
            </div>
          </div>

          <!-- 左列表盒子 -->
          <div class="fl_box">
            <div
              class="fl_item"
              v-for="(item, index) in branchListFl"
              :key="index"
            >
              <img :src="item.img" alt="" />
              <span>{{ item.title }}</span>
            </div>
          </div>

          <div class="fr_box">
            <div
              class="fr_item"
              v-for="(item, index) in branchListFr"
              :key="index"
            >
              <img :src="item.img" alt="" />
              <span>{{ item.title }}</span>
            </div>
          </div>

          <div class="decoration_box">
            <div
              class="decoration_box_item"
              v-for="(item, index) in 7"
              :key="index"
            >
              <img :src="contentImg7" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="card_box">
        <div
          class="card_box_item"
          v-for="(item, index) in cardIconList"
          :key="index"
        >
          <div class="top">
            <span>{{ item.tabName }}</span>
            <img :src="iconArrow2" alt="" />
          </div>
          <div class="bottom">
            <img
              :style="{ width: `${item.width}`, height: `${item.height}` }"
              :src="item.imgIcon"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// 顶部盒子图片
const navImg = require("@/assets/images/imitatepage/household-energy-storage/icon_top1.png");
const navImg2 = require("@/assets/images/imitatepage/household-energy-storage/icon_top2.png");
const navImg3 = require("@/assets/images/imitatepage/household-energy-storage/icon_top3.png");
const statusImg = require("@/assets/images/imitatepage/household-energy-storage/icon_top4.png");
const iconArrow = require("@/assets/images/imitatepage/household-energy-storage/icon_arrow.png");

// 主体图片
const contentImg = require("@/assets/images/imitatepage/household-energy-storage/icon_content.png");
const contentImg7 = require("@/assets/images/imitatepage/household-energy-storage/icon_07.png");

//右箭头图标
const iconArrow2 = require("@/assets/images/imitatepage/household-energy-storage/icon_arrow2.png");

//分支列表
const branchListFl = [
  {
    title: "家用",
    img: require("@/assets/images/imitatepage/household-energy-storage/icon_01.png"),
  },
  {
    title: "太阳能",
    img: require("@/assets/images/imitatepage/household-energy-storage/icon_03.png"),
  },
  {
    title: "发电机",
    img: require("@/assets/images/imitatepage/household-energy-storage/icon_04.png"),
  },
];

const branchListFr = [
  {
    title: "INFI",
    img: require("@/assets/images/imitatepage/household-energy-storage/icon_02.png"),
  },
  {
    title: "市电",
    img: require("@/assets/images/imitatepage/household-energy-storage/icon_05.png"),
  },
];

// 底部操作图标
const cardIconList = [
  {
    imgIcon: require("@/assets/images/imitatepage/household-energy-storage/icon_card1.png"),
    tabName: "数据",
    width: "60px",
    height: "48px",
  },
  {
    imgIcon: require("@/assets/images/imitatepage/household-energy-storage/icon_card2.png"),
    tabName: "设置",
    width: "68px",
    height: "42px",
  },
  {
    imgIcon: require("@/assets/images/imitatepage/household-energy-storage/icon_card3.png"),
    tabName: "负载控制",
    width: "50px",
    height: "39px",
  },
  {
    imgIcon: require("@/assets/images/imitatepage/household-energy-storage/icon_card4.png"),
    tabName: "备电历史",
    width: "60px",
    height: "42px",
  },
];
</script>

<style lang="scss" scoped>
.household-energy-storage {
  position: relative;
  width: 100%;
  height: 100%;

  .wrap-content {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    box-sizing: border-box;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    padding: 0 2px;
    background-color: #000000 !important;

    // 顶部视口盒子
    .top_box {
      color: white;
      .top-title {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        justify-items: center;
        margin-top: 55px;
        padding: 0 12px;
        .left {
          display: flex;
          align-items: center;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .theme-title {
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 17px;

          img {
            width: 9px;
            height: 6px;
            margin-left: 8px;
            margin-top: 3px;
          }
        }
        .right {
          position: relative;
          display: flex;
          align-items: center;

          img {
            width: 24px;
            height: 24px;

            &:last-child {
              position: absolute;
              width: 10px;
              height: 10px;
              top: -1px;
              right: -5px;
            }
          }
        }
      }

      .status_box {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 15px;
        margin-top: 30px;
        font-size: 13px;
        color: white;
        .line {
          width: 0px;
          height: 12px;
          opacity: 1;
          border: 1px solid #666666;
          margin: 0 8px 0 15px;
        }

        img {
          width: 9px;
          height: 15px;
          margin-right: 6px;
        }
      }
    }

    // 内容
    .content-box {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      height: fit-content;
      margin-top: 20px;

      .images-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 316px;
        height: 288px;
        // 主体样式
        .containe {
          position: relative;
          width: 140px;
          height: 140px;
          z-index: 2;

          img {
            width: 100%;
            height: 100%;
          }

          span {
            position: absolute;
            font-size: 13px;
            top: 83px;
            left: 58px;
            color: white;
          }

          .mask {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 50%;
            box-shadow: inset 0 0 36px 28px #0d6229a6;
          }
        }

        //左分支盒子
        .left_line_box {
          position: absolute;
          top: 30px;
          left: 36px;
          height: 232px;
          width: 83px;

          .line_item {
            width: 100%;
            height: 58px;
            box-sizing: border-box;
            // border: 1px solid #06dea7;
            // border-radius: 8px;

            &:first-child {
              @extend .top_border, .right_radius;
            }
            &:nth-child(2n) {
              @extend .bottom_border, .right_bot_radius;
            }

            &:nth-child(3) {
              border-right: 1px solid #06dea7;
            }
          }
          // 上、右边框
          .top_border {
            border-top: 1px solid #06dea7;
            border-right: 1px solid #06dea7;
            opacity: 0.4;
          }

          // 下、右边框
          .bottom_border {
            border-bottom: 1px solid #06dea7;
            border-right: 1px solid #06dea7;
            opacity: 0.4;
          }

          // 右上圆角
          .right_radius {
            border-radius: 0 7px 0 0;
          }

          // 右下角圆角
          .right_bot_radius {
            border-radius: 0 0 7px 0;
          }
        }

        .right_line_box {
          position: absolute;
          top: 30px;
          right: 36px;
          height: 232px;
          width: 83px;

          .out_line {
            padding: 6px 0 6px 6px;
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            border-radius: 7px 0 0 7px;
            border: 1px solid #06dea7;
            border-right: none;
            overflow: hidden;
            opacity: 0.4;

            .in_line {
              height: 100%;
              width: 100%;
              border: 1px solid #06dea7;
              border-right: none;
              border-radius: 7px;
              border-radius: 6px 0 0 6px;
              box-sizing: border-box;
            }
          }
        }

        .fl_box {
          position: absolute;
          top: 12px;
          left: 0;
          height: 100%;
          display: grid;
          grid-template-rows: auto 1fr auto;

          .fl_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            color: white;

            img {
              width: 36px;
              height: 36px;
              margin-bottom: 3px;
            }
          }
        }

        .fr_box {
          position: absolute;
          top: 12px;
          right: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .fr_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            color: white;

            img {
              width: 36px;
              height: 36px;
              margin-bottom: 3px;
            }
          }
        }

        // 定位小盒子
        .decoration_box {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;

          &_item {
            display: flex;
            position: absolute;
            width: 10px;
            height: 10px;
            img {
              width: 100%;
              height: 100%;
            }

            &:nth-child(1) {
              top: 26px;
              left: 97px;
            }
            &:nth-child(2) {
              top: 26px;
              left: 257px;
            }
            &:nth-child(3) {
              top: 45px;
              left: 200px;
            }
            &:nth-child(4) {
              top: 140px;
              left: 44px;
            }
            &:nth-child(5) {
              top: 205px;
              left: 113px;
            }
            &:nth-child(6) {
              top: 213px;
              left: 192px;
            }
            &:nth-child(7) {
              top: 249px;
              left: 232px;
            }
          }
        }
      }
    }

    .card_box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      padding: 8px 15px 20px;
      &_item {
        position: relative;
        width: 164px;
        height: 103px;
        background: #222222;
        border-radius: 8px;
        color: white;
        padding: 10px;
        box-sizing: border-box;

        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            width: 12px;
            height: 12px;
          }
        }

        .bottom {
          position: absolute;
          bottom: 10px;
          left: -10px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          width: 100%;
          height: fit-content;
        }
      }
    }
  }
}
</style>
